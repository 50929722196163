import { createContext, useContext, useState } from "react";

const PatientContext = createContext();

export const PatientProvider = (props) => {
  const [loading, setLoading] = useState(true);
  const [availableSlots, setAvaliableSlots] = useState([]);
  return <PatientContext.Provider value={{ availableSlots, setAvaliableSlots, loading, setLoading }} >
    {props.children}
  </PatientContext.Provider>
}

export function usePatientContext() {
  return useContext(PatientContext);
}

export default PatientContext;