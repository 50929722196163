import React from 'react'
import "./Loader.css"
const Loader = () => {
  return (
 
    <div className="spinner"></div>
  
  )
}

export default Loader